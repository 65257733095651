define("bcm-io-legacy/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQ8Lg3Wg",
    "block": "[[[10,\"title\"],[12],[1,[30,0,[\"model\",\"title\"]]],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"description\"],[15,\"content\",[30,0,[\"model\",\"description\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:site_name\"],[14,\"content\",\"Beavercreek Marketing\"],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:title\"],[15,\"content\",[30,0,[\"model\",\"title\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:description\"],[15,\"content\",[30,0,[\"model\",\"description\"]]],[12],[13],[1,\"\\n\"],[41,[30,0,[\"model\",\"imageURL\"]],[[[10,\"meta\"],[14,\"property\",\"og:image\"],[15,\"content\",[30,0,[\"model\",\"imageURL\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"twitter:card\"],[14,\"content\",\"summary_large_image\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"model\",\"videoURL\"]],[[[10,\"meta\"],[14,\"property\",\"og:video\"],[15,\"content\",[30,0,[\"model\",\"videoURL\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"twitter:player\"],[15,\"content\",[30,0,[\"model\",\"videoURL\"]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"model\",\"pageURL\"]],[[[10,\"meta\"],[14,\"property\",\"og:url\"],[15,\"content\",[30,0,[\"model\",\"pageURL\"]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"model\",\"appID\"]],[[[10,\"meta\"],[14,3,\"apple-itunes-app\"],[15,\"content\",[30,0,[\"model\",\"appID\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "bcm-io-legacy/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});