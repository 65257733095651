define("bcm-io-legacy/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9vS/aao1",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[8,[39,4],null,null,null],[1,\"\\n\"]],[],false,[\"head-layout\",\"main-nav\",\"component\",\"-outlet\",\"footer\"]]",
    "moduleName": "bcm-io-legacy/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});