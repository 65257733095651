define("bcm-io-legacy/templates/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wgCOifud",
    "block": "[[[10,0],[14,0,\"bloc none l-bloc \"],[14,1,\"packages-title\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"container bloc-no-padding\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"row d-flex justify-content-center mt-5 mb-5\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"col-12 col-md-6\"],[12],[1,\"\\n                \"],[10,\"h1\"],[12],[1,\"This page cannot be found.\"],[13],[1,\"\\n                \"],[10,2],[12],[1,\"Please check the url or view a page listed below.\"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "bcm-io-legacy/templates/four-oh-four.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});