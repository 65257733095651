define("bcm-io-legacy/initializers/load-bootstrap-config", ["exports", "bcm-io-legacy/config/environment", "ember-bootstrap/config", "ember-bootstrap/version"], function (_exports, _environment, _config, _version) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* container, application */
  initialize() {
    _config.default.load(_environment.default['ember-bootstrap'] || {});

    (0, _version.registerLibrary)();
  }

  var _default = {
    name: 'load-bootstrap-config',
    initialize: initialize
  };
  _exports.default = _default;
});