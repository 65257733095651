define("bcm-io-legacy/modifiers/search-content", ["exports", "ember-modifier", "jsrender", "jquery"], function (_exports, _emberModifier, _jsrender, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function searchContent(element
  /*, params, hash*/
  ) {
    var jsrender = require("jsrender");

    var tmplCard = jsrender.templates("myCard", '<!-- contentcardscontainerrow --> <div id="{{:title}}" class="col-6 col-md-4 col-lg-3 m-0 p-2 myCard"><div class="d-none">{{:category}}</div><a data-lc-href="https://www.onlinebanktours.com/external/v5/player.php?key={{:key}}&amp;loop=true" data-lc-options="{&quot;showTitle&quot;:&quot;false&quot;,&quot;fixedRatio&quot;:&quot;false&quot;,&quot;showSequenceInfo&quot;: &quot;false&quot;, &quot;iframe&quot;:{&quot;width&quot;: 970, &quot;height&quot;: 500}}" href="" title="{{:title}}" data-rel="lightcase:Videos"><div class="card h-100 shadow"><div class="p-0 card-header"><div class="text-right d-flex justify-content-end position-absolute w-100"><span class="text-light fa fa-play-circle align-self-end m-3 float-lg-right icon-sm float-right"></span></div><img src="https://www.bcm.io/img/lazyload-ph.png" data-src="{{:preview}}" onclick="return false" oncontextmenu="return false" ondragstart="return false" height="176px" width="264px" class="cover lazyload mx-auto d-block embed-responsive embed-responsive-16by9 rounded-top" alt="{{:title}}"></div><div class="modal-body pb-2 d-flex align-items-center"><p class="h5 mg-md p-0 text-dark m-0">{{:title}}</p></div><div class=" m-0 pt-0 pb-3 pr-3 pl-3">{{if type=="c"}}<p class="text-white badge mb-0 badge-primary">consumer</p></div></div></a></div>{{else type=="b"}}<p class="text-white badge mb-0 badge-success">business</p></div></div></a></div>{{else}}  <p class="text-white badge mb-0 badge-secondary">promo</p></div></div></a></div>{{/if}}');
    var tmpl = jsrender.templates("myCategory", '<div id="templaterow" class="card m-2 mb-3 myCategory"><div class="card-header bg-light p-0 pl-3 pr-3 pt-3 pb-0 border-0"><h2 class="mg-md text-dark m-0 h2-style">{{:category}}</h2></div><div class="card-body bg-light pt-1 pb-1"><div> <div class="myCardContain row p-1"> {{for videos tmpl="myCard"/}} </div><!-- contentcardscontainerrow END --></div></div></div><!-- templaterow end-->');
    var fetchData = "https://www.bcm.io/pageSpecific/video/nestedVideos.json?v=2022.12.13";
    dataLoading();

    _jquery.default.getJSON(fetchData, {
      tagmode: "any",
      format: "json"
    }).done(function (data) {
      var html = tmpl.render(data);
      (0, _jquery.default)("#result").html(html);
      dataLoaded();
    });

    (0, _jquery.default)("#videosearchbtn").on('click', function (e) {
      e.preventDefault();
      (0, _jquery.default)("#searchvideos").trigger("blur");
    });
    var toggleStatus = "thumb";
    (0, _jquery.default)("#thumbtn").on('click', function (e) {
      e.preventDefault();
      (0, _jquery.default)(".myCard .card-header").show();
      (0, _jquery.default)(".myCard").addClass("col-6 col-md-4 col-lg-3").removeClass("col-12");
      (0, _jquery.default)("#thumbtn").addClass("active");
      (0, _jquery.default)("#listbtn").removeClass("active");
      toggleStatus = "thumb";
    });
    (0, _jquery.default)("#listbtn").on('click', function (e) {
      e.preventDefault();
      (0, _jquery.default)(".myCard .card-header").hide();
      (0, _jquery.default)(".myCard").removeClass("col-6 col-md-4 col-lg-3").addClass("col-12");
      (0, _jquery.default)("#listbtn").addClass("active");
      (0, _jquery.default)("#thumbtn").removeClass("active");
      toggleStatus = "list";
    });

    function dataLoading() {
      var html = '<div class="row"><div class="col-12 d-flex justify-content-center"><div class="spinner-border m-5" role="status"><span class="sr-only">Loading...</span></div></div><div>';
      (0, _jquery.default)("#result").html(html);
    }

    function dataLoaded() {
      if (window.location.hash) {
        var hash = window.location.hash.substring(1);
        console.log(hash);

        if (hash == "list") {
          (0, _jquery.default)("#listbtn").trigger('click');
        }
      }

      (0, _jquery.default)('a[data-rel^=lightcase]').lightcase();
    }

    (0, _jquery.default)("#searchvideos").keyup(function () {
      var value = (0, _jquery.default)(this).val().toLowerCase();
      (0, _jquery.default)(".myCard *").filter(function () {
        (0, _jquery.default)(this).parentsUntil(".myCardContain").toggle((0, _jquery.default)(this).parentsUntil(".myCardContain").text().toLowerCase().indexOf(value) > -1);
      });

      if (toggleStatus == "thumb") {
        (0, _jquery.default)("#thumbtn").trigger('click');
      } else {
        (0, _jquery.default)("#listbtn").trigger('click');
      }
    }).keydown(function (event) {
      if (event.which == 13) {
        (0, _jquery.default)("#searchvideos").blur();
      }
    });
  });

  _exports.default = _default;
});