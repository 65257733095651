define("bcm-io-legacy/templates/forms/holidaycheer2021", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XgPEGJ/T",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"bloc l-bloc pb-3 pt-3 pl-3 pr-3 bg-light border-top border-bottom\"],[14,1,\"bloc-4\"],[12],[1,\"\\n   \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"row p-1 d-flex-fluid align-items-center\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@model\",\"@onSubmit\"],[[30,0],[28,[37,1],[[30,0],\"save\"],null]]],[[\"default\"],[[[[1,\"\\n\\n            \"],[8,[30,1,[\"element\"]],null,[[\"@property\",\"@label\"],[\"email\",\"email\"]],null],[1,\"\\n            \"],[8,[30,1,[\"element\"]],null,[[\"@property\",\"@label\",\"@controlType\"],[\"password\",\"password\",\"password\"]],null],[1,\"\\n            \"],[8,[30,1,[\"submitButton\"]],null,null,[[\"default\"],[[[[1,\"Login\"]],[]]]]],[1,\"\\n\\n        \"]],[1]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"form\"],false,[\"bs-form\",\"action\"]]",
    "moduleName": "bcm-io-legacy/templates/forms/holidaycheer2021.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});