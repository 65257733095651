define("bcm-io-legacy/templates/tools/learning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eD0W1qEp",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"container bloc-lg align-items-center mb-4\"],[12],[1,\"\\n\"],[10,0],[14,0,\"row d-flex justify-content-center mx-auto\"],[14,5,\"max-width:900px\"],[12],[1,\"\\n\\n\\n\"],[10,0],[14,0,\"row mx-auto justify-content-center\"],[14,5,\"max-width:900px\"],[12],[1,\"\\n\"],[10,0],[14,1,\"Title\"],[14,0,\"col-12 text-center justify-content-center align-items-center\"],[14,5,\"padding-bottom:4em\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"The New Learning Center\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\\n\\n\\n\\t\"],[10,0],[14,0,\"pt-3 mt-3 col-12\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"card text-left\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"card-header bg-light\"],[12],[1,\"\\n\\t   \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[1,\"New Features Include:\"],[13],[1,\"\\n\\t \"],[13],[1,\"\\n\\t\"],[10,0],[14,0,\"p-4 m-2 text-left\"],[12],[1,\"\\n\\t\"],[10,\"ul\"],[14,0,\"m-3\"],[12],[1,\"\\n\\t\\t\"],[10,\"li\"],[12],[1,\"Create and manage user groups\"],[13],[1,\"\\n\\t\\t\"],[10,\"li\"],[12],[1,\"Track progress and quiz scores\"],[13],[1,\"\\n\\t\\t\"],[10,\"li\"],[12],[1,\"Auto notification of new courses available\"],[13],[1,\"\\n\\t\\t\"],[10,\"li\"],[12],[1,\"Create your own course from scratch, or choose and customize courses from a library of Beavercreek materials, including:\"],[13],[1,\"\\n\\t\\t\"],[10,\"ul\"],[14,0,\"m-3\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"li\"],[12],[1,\"OEC and Money IQ videos\"],[13],[1,\"\\n\\t\\t\\t\"],[10,\"li\"],[12],[1,\"Uploaded powerpoint slides\"],[13],[1,\"\\n\\t\\t\\t\"],[10,\"li\"],[12],[1,\"Handout attachments\"],[13],[1,\"\\n\\t\\t\\t\"],[10,\"li\"],[12],[1,\"Quizzes\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\n\\n\\n\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "bcm-io-legacy/templates/tools/learning.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});