define("bcm-io-legacy/modifiers/page-container", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function pageContainer(element
  /*, params, hash*/
  ) {
    $(function () {
      // wait for document ready
      $('.btn').removeClass("wire-btn-black");
      $('.btn').removeClass("btn-wire"); //Check if browser is IE

      if (navigator.userAgent.match(/msie|trident/i)) {
        $("#usdzbtn").hide();
      } else {
        var a = document.createElement("a");

        if (a.relList.supports("ar")) {
          $("#usdzbtn").show();
          $("#usdzbtn").rel = "ar";
        } else {
          $("#usdzbtn").hide();
        }

        ;
      }

      ;
      setUpBackgroundScenes();
      setUpDynamicScenes();
      window.addEventListener('resize', resetDynamicScenes());
      LDS4ActiveState();
    });

    function setUpBackgroundScenes() {
      // scene1
      var ctrl = new ScrollMagic.Controller();
      var t1 = gsap.timeline().from(".page-container", {
        backgroundColor: '#FFFFFF'
      });
      var scene1 = new ScrollMagic.Scene({
        triggerElement: "#row1",
        triggerHook: '1'
      }).setTween(t1).addTo(ctrl); // scene 2

      var ctrl2 = new ScrollMagic.Controller();
      var t2 = gsap.timeline().to(".page-container", {
        backgroundColor: '#000000'
      }).fromTo(".fa-chevron-up", {
        color: '#000000'
      }, {
        color: '#FFFFFF'
      });
      var scene2 = new ScrollMagic.Scene({
        triggerElement: "#row2",
        triggerHook: 'onCenter'
      }).setTween(t2).addTo(ctrl2); // scene5

      var ctrl5 = new ScrollMagic.Controller();
      var t5 = gsap.timeline().fromTo(".page-container", {
        backgroundColor: '#000000'
      }, {
        duration: .5,
        backgroundColor: '#FFFFFF'
      }).fromTo(".fa-chevron-up", {
        color: '#FFFFFF'
      }, {
        duration: .5,
        color: '#000000'
      });
      var scene5 = new ScrollMagic.Scene({
        triggerElement: "#row5",
        triggerHook: '0.5'
      }).setTween(t5).addTo(ctrl5);
    }

    function setUpDynamicScenes() {
      $('.hero').each(function (index, element) {
        var h, w, name;
        name = ".hero" + index;
        h = $(name).innerHeight();
        w = $(name).innerWidth();
        var ctrlVarOut = new ScrollMagic.Controller();
        var tAheadOut = gsap.to(".hero" + index, {
          css: {
            opacity: 0.0
          }
        });
        var ctrlVarIn = new ScrollMagic.Controller();
        var ctrlVarCent = new ScrollMagic.Controller();
        var tVarCentIn = gsap.timeline().fromTo(name, {
          css: {
            opacity: 0.0
          }
        }, {
          duration: 0.5,
          css: {
            opacity: 1.0
          }
        });
        var tVarCentOut = gsap.timeline().fromTo(name, {
          css: {
            opacity: 1.0
          }
        }, {
          duration: 0.5,
          css: {
            opacity: 0.0
          }
        });
        new ScrollMagic.Scene({
          triggerHook: 'onCenter',
          triggerElement: name
        }).setTween(tVarCentIn).addTo(ctrlVarIn);
        new ScrollMagic.Scene({
          triggerHook: 'onCenter',
          triggerElement: name,
          offset: h * .89
        }).setTween(tVarCentOut).addTo(ctrlVarOut);
      }); //hero each
    }

    function resetDynamicScenes() {
      $('.hero').each(function (index, element) {
        name = ".hero" + index;
        h = $(name).innerHeight();
        w = $(name).innerWidth();
        var tAheadOut = gsap.to(".hero" + index, {
          css: {
            opacity: 0.0
          }
        });
        var ctrlVarOut = new ScrollMagic.Controller();
        var ctrlVarIn = new ScrollMagic.Controller();
        var ctrlVarCent = new ScrollMagic.Controller();
        var tVarCentIn = gsap.timeline().fromTo(name, {
          css: {
            opacity: 0.0
          }
        }, {
          duration: 0.5,
          css: {
            opacity: 1.0
          }
        });
        var tVarCentOut = gsap.timeline().fromTo(name, {
          css: {
            opacity: 1.0
          }
        }, {
          duration: 0.5,
          css: {
            opacity: 0.0
          }
        });
        new ScrollMagic.Scene({
          triggerHook: 'onCenter',
          triggerElement: name
        }).setTween(tVarCentIn).addTo(ctrlVarIn);
        new ScrollMagic.Scene({
          triggerHook: 'onCenter',
          triggerElement: name,
          offset: h * .88
        }).setTween(tVarCentOut).addTo(ctrlVarOut);
      });
    }
  });

  _exports.default = _default;
});