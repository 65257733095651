define("bcm-io-legacy/modifiers/lightcase-init", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function lightcaseInit(element
  /*, params, hash*/
  ) {
    $('a[data-rel^=lightcase]').lightcase();
  });

  _exports.default = _default;
});